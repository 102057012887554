import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
// import ElementUI from "element-ui";
// Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
import "./assets/font/font.css";

// Google Analytics
// import VueGtag from "vue-gtag"
// Vue.use(VueGtag, {
//   config: {
//     id: "UA-191422719-1",
//     params: {
//       send_page_view: true
//     }
//   }
// }, router)

//main.js 中添加下面这行代码（路径和文件名按自己的来）
import './plugins/element'

// 全局混入写法
import myMixin from "./mixin/index";
Vue.mixin(myMixin);


Vue.config.productionTip = false;

import "./icons/index";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
