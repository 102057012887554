// mixin 混入, 是指可以将一个符合 Vue 配置要求的对象, 合并到组件当中
export default {
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 价格千分位
    setSumPrice(price, num, locales = "zh-CN", currency = "JPY") {
      if (!price) {
        return false;
      }

      if (num < 1) {
        return "-";
      }
      var sum_price = price * num;
      // console.log(sum_price);

      var totalPrice = sum_price.toLocaleString(locales, {});
      // console.log(totalPrice);
      return totalPrice;
    },
    // 翻译 英文 月份
    translateMonth(mm) {
      if (mm == "01") {
        return "January";
      } else if (mm == "02") {
        return "February";
      } else if (mm == "03") {
        return "March";
      } else if (mm == "04") {
        return "April";
      } else if (mm == "05") {
        return "May";
      } else if (mm == "06") {
        return "June";
      } else if (mm == "07") {
        return "July";
      } else if (mm == "08") {
        return "August";
      } else if (mm == "09") {
        return "September";
      } else if (mm == "10") {
        return "October";
      } else if (mm == "11") {
        return "November";
      } else if (mm == "12") {
        return "December";
      }
    },
  },
};
