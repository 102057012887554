import vue from "vue";
import VueRouter from "vue-router";
vue.use(VueRouter);

// 导航到当前路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  // pc
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    component: () => import("@/views/home"),
  },
  {
    path: "/info",
    component: () => import("@/views/info"),
    meta: {
      keepAlive: false, // 需要缓存
    },
  },
  {
    path: "/payment",
    component: () => import("@/views/payment"),
  },
  {
    path: "/order_preview",
    component: () => import("@/views/order_preview"),
  },
  {
    path: "/service",
    component: () => import("@/views/service"),
    meta: {
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/privacy",
    component: () => import("@/views/privacy"),
    meta: {
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/contact",
    component: () => import("@/views/contact"),
    meta: {
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/notice",
    component: () => import("@/views/notice"),
  },
  {
    path: "*",
    component: () => import("@/views/404"),
  },

  // 移动
  {
    path: "/m_home",
    component: () => import("@/mobile/m_home"),
  },
  {
    path: "/m_info",
    component: () => import("@/mobile/m_info"),
    meta: {
      keepAlive: false, // 需要缓存
    },
  },
  {
    path: "/m_payment",
    component: () => import("@/mobile/m_payment"),
  },
  {
    path: "/m_payment-copycopy",
    component: () => import("@/mobile/m_payment-copycopy"),
  },
  {
    path: "/m_order_preview",
    component: () => import("@/mobile/m_order_preview"),
  },
  {
    path: "/m_service",
    component: () => import("@/mobile/m_service"),
    meta: {
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/m_privacy",
    component: () => import("@/mobile/m_privacy"),
    meta: {
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/m_contact",
    component: () => import("@/mobile/m_contact"),
    meta: {
      keepAlive: true, // 需要缓存
    },
  },
  {
    path: "/m_notice",
    component: () => import("@/mobile/m_notice"),
  },
  {
    path: "/m_404",
    component: () => import("@/mobile/m_404"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
