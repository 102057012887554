// element.js 全文内容如下，按自己需要引入就好了
import Vue from 'vue'
import {
    InputNumber,
    Button,
    Form,
    FormItem,
    Input,
    CheckboxGroup,
    Checkbox,
    Message,
    Dialog,
    MessageBox,
    Radio,
    RadioGroup,
    Loading,
    Drawer,
    Popover,
    // Alert
    // Icon,
} from 'element-ui'
Vue.use(Button)
Vue.use(InputNumber)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Drawer)
Vue.use(Loading)
Vue.use(Popover)
// Vue.use(Alert)
// Vue.use(Icon)
// Vue.use(Container)
// Vue.use(Header)
// Vue.use(Aside)
// Vue.use(Main)
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
// Vue.use(Card)
// Vue.use(Row)
// Vue.use(Col)
// Vue.use(Table)
// Vue.use(TableColumn)
// Vue.use(Switch)
// Vue.use(Tooltip)
// Vue.use(Pagination)
// Vue.use(Tag)
// Vue.use(Tree)
// Vue.use(Select)
// Vue.use(Cascader)
// Vue.use(Alert)
// Vue.use(Tabs)
// Vue.use(TabPane)
// Vue.use(Option)

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert