<template>
  <div>
    <!-- pc -->
    <div v-if="topDialogQuanBr" class="topDialogQuanju">
      <div class="topDialogQuanju_m">
        <span>
          This booking site is operated by JTRWeb LTD. - an authorised ticket
          reseller of Moominvalley Park.
        </span>
        <el-button @click="topDialogQuanBr = false"
          ><i class="el-icon-close"></i
        ></el-button>
      </div>
    </div>
    <!-- mb -->
    <div v-if="topDialogQuanBrMb" class="topDialogQuanju_mb">
      <div class="topDialogQuanju_m">
        <span>
          This booking site is operated by JTRWeb LTD. - an authorised ticket
          reseller of Moominvalley Park.
        </span>
        <el-button @click="topDialogQuanBrMb = false"
          ><i class="el-icon-close"></i
        ></el-button>
      </div>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topDialogQuanBr: false,
      topDialogQuanBrMb: false,
    };
  },
  created() {
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      this.topDialogQuanBrMb = true;
    } else {
      // 跳转至 pc 端路由
      this.topDialogQuanBr = true;
    }
  },
};
</script>

<style lang="less">
.homePopover {
  width: 665px;
  border-radius: 12px !important;
  padding: 40px !important;
  color: #12334c !important;
  .el-popover__title {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .mainDlg {
    & > p {
      margin-bottom: 10px;
      font-size: 15px;
      word-break: break-word;
      text-align: left;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // height: 385px;
  // overflow: auto;
  // &::-webkit-scrollbar {
  //   width: 5px;
  // }
  // &::-webkit-scrollbar-track {
  //   background-color: #fff;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: #cccccc;
  //   border-radius: 25px;
  // }
}

.topDialogQuanju {
  height: 41px;
  background: #007bac;
  display: flex;
  min-width: 1030px;
  .topDialogQuanju_m {
    display: inline-block;
    width: 1000px;
    height: 100%;
    margin: 0 auto;
    color: #fff;
    line-height: 41px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  .el-button {
    background: rgba(255, 255, 255, 0);
    border: 0;
    color: #fff;
    padding: 5px;
    &:hover {
      background: rgba(255, 255, 255, 0);
      color: #fff;
    }
    &:focus {
      color: #fff;
      border-color: #c6e2ff;
      background-color: #ecf5ff00;
    }
  }
  .el-icon-close:before {
    font-size: 18px;
  }
}
.topDialogQuanju_mb {
  background: #007bac;
  padding: 8px 4.2667vw;
  .topDialogQuanju_m {
    display: inline-block;
    height: 100%;
    margin: 0 auto;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  .el-button {
    background: rgba(255, 255, 255, 0);
    border: 0;
    color: #fff;
    padding: 5px;
    &:hover {
      background: rgba(255, 255, 255, 0);
    }
    &:active {
      color: #fff;
      border-color: #fff;
      outline: 0;
    }
  }
  .el-icon-close:before {
    font-size: 20px;
  }
}
body {
  background: #e3f3ff;
  // font-family: MEDIUM, REGULAR, SEMIBOLD;
  // font-family: Regular;
  // font-family: regular2;
  font-family: regularTtf;
}
.el-button {
  font-family: regularTtf;
  font-weight: 700;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
a {
  text-decoration-line: none;
}
i {
  font-style: normal;
}
</style>